<script setup lang="ts">
import { localizeSearchScope, SearchScope } from '@portfolio'
import type { Ref } from 'vue'
import type { DashboardFilters } from '~/composables/useAPI/useApiDashboard'
import { DashboardFiltersSymbol } from '@dashboard'

const modelValue = defineModel<SearchScope>()

const filters = inject<Ref<DashboardFilters>>(DashboardFiltersSymbol)!

const divisionId = getCurrentDivisionId()
const { data } = useApiDashboardFilter(divisionId, 'scope', filters)

const scopes = computed(() => {
  const _data = (data.value ?? []).filter(
    (value) =>
      value === SearchScope.HEAD_LEASES || value === SearchScope.SUB_LEASES,
  )
  return _data.map((value) => {
    if (value !== SearchScope.SUB_LEASES) {
      modelValue.value = SearchScope.HEAD_LEASES
    }

    return {
      label: localizeSearchScope(value as SearchScope),
      value,
    }
  })
})
const HIDE_FILTER = true
</script>

<template>
  <div v-if="scopes.length > 1">
    <FormKit
      v-model="modelValue"
      type="listbox"
      :value="SearchScope.ALL"
      :options="scopes"
      outer-class="w-52"
    >
      <template #prefix>
        <div
          class="flex items-center rounded-l bg-gray-700 px-2.5 pt-px text-xs"
        >
          Type
        </div>
      </template>
    </FormKit>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.formkit-inner) {
  @apply backdrop-blur-xl backdrop-saturate-[180%];
}
</style>
